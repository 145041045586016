import React from 'react'

import styled from 'styled-components'

import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import { fonts } from 'theme'

type LocalListItemProps = {
    children: string | (React.ReactNode & string) | React.ReactNode
}

export type ListItemProps = LocalListItemProps & BoxProps

/*

    Creates a styled ListItem for our content

    If we want to use this more widely, might want to 
    move to Components and add to component system more
    formally (docs etc)

*/

export const ListItem = ({ children, as, className, color, ...props }: ListItemProps) => (
    <_ListItem as={as} {...{ className, color }} {...props}>
        {children}
    </_ListItem>
)

export default ListItem

export const ListItemDefaultProps: Partial<ListItemProps> = {
    color: 'text',
    as: 'li',
}
ListItem.defaultProps = ListItemDefaultProps

const _ListItem = styled(Box)<ListItemProps>`
    font-weight: ${fonts.weight.normal};
    line-height: 1.4;
    margin-bottom: 0.5rem;
`
