import { type RefObject } from 'react'

import { useRouter } from 'next/router'

import useTrackClick from './useTrackClick'
import useTrackImpression from './useTrackImpression'
import { useTrackLoadedOnPage } from './useTrackLoadedOnPage'

import type { AdvertTrackingProps } from 'lib/analyticsApi'
import { trackProductAdvert } from 'lib/analyticsApi'

export const useAdvertisingTracking = (
    trackedElementRef: RefObject<HTMLElement>,
    trackingParams: Omit<AdvertTrackingProps, 'pathname'>,
) => {
    const { pathname } = useRouter()

    const trackingParamsWithPathname = {
        ...trackingParams,
        pathname,
    }

    const pageLoadTrackingFunction = () =>
        trackProductAdvert('Page Load', trackingParamsWithPathname)

    const impressionTrackingFunction = () =>
        trackProductAdvert('Impression', trackingParamsWithPathname)

    const clickTrackingFunction = () => trackProductAdvert('Click', trackingParamsWithPathname)

    useTrackLoadedOnPage(pageLoadTrackingFunction)
    useTrackImpression(trackedElementRef, impressionTrackingFunction)

    const trackClick = useTrackClick(clickTrackingFunction)

    return { onClick: trackClick }
}
