import React from 'react'

import MarkdownParser from './MarkdownParser'

export interface TextProps {
    input: string
}

const Text = ({ input }: TextProps) => {
    return <MarkdownParser>{input}</MarkdownParser>
}

export default Text
