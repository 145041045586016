import React from 'react'

import { graphql, useFragment } from 'react-relay'

import type { ImageProps } from '../Image'

import type { AccessibleImage_accessibleImage$key } from '__generated__/AccessibleImage_accessibleImage.graphql'
import Image from 'components/Image'
import type { Omit } from 'types'

type WrappedImageProps = Omit<ImageProps, 'src'>

export type AccessibleImageProps = {
    accessibleImage: AccessibleImage_accessibleImage$key
} & Omit<WrappedImageProps, 'alt'>

/**

    This is a wrapper around the ServerImage component that
    enforces an alt tag.

    We are now deprecating ServerImage in favor of this accessible
    image component.

*/

const AccessibleImage = ({ accessibleImage: accessibleImageKey, ...props }: AccessibleImageProps) => {
    const { full, altText } = useFragment(AccessibleImageFragment, accessibleImageKey)

    return <Image src={full} alt={altText} {...props} />
}

const AccessibleImageFragment = graphql`
    fragment AccessibleImage_accessibleImage on AccessibleURLImage {
        full
        altText
    }
`

export default AccessibleImage
