import { useEffect } from 'react'

/**
 * For the purposes of tracking adverts, tracks if the page the component is on has been loaded.
 *
 * This is different to an impression. As impressions are tracked when the advert is scrolled into view.
 */
export const useTrackLoadedOnPage = (trackingFunction: () => void) => {
    useEffect(() => {
        trackingFunction()
    }, []) // Run only on mount
}
