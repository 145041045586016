/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BannerAdvert_activeProductAdvert = {
    readonly title: string;
    readonly description: string;
    readonly destinationUrl: string;
    readonly sponsoringCompany: {
        readonly name: string;
    };
    readonly wideImage: {
        readonly " $fragmentRefs": FragmentRefs<"AccessibleImage_accessibleImage">;
    } | null;
    readonly narrowImage: {
        readonly " $fragmentRefs": FragmentRefs<"AccessibleImage_accessibleImage">;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"AdvertWrapper_activeProductAdvert">;
    readonly " $refType": "BannerAdvert_activeProductAdvert";
};
export type BannerAdvert_activeProductAdvert$data = BannerAdvert_activeProductAdvert;
export type BannerAdvert_activeProductAdvert$key = {
    readonly " $data"?: BannerAdvert_activeProductAdvert$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"BannerAdvert_activeProductAdvert">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "AccessibleImage_accessibleImage"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BannerAdvert_activeProductAdvert",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "destinationUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SponsoringCompany",
      "kind": "LinkedField",
      "name": "sponsoringCompany",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessibleURLImage",
      "kind": "LinkedField",
      "name": "wideImage",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessibleURLImage",
      "kind": "LinkedField",
      "name": "narrowImage",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdvertWrapper_activeProductAdvert"
    }
  ],
  "type": "ProductAdvert",
  "abstractKey": null
};
})();
(node as any).hash = '3a7cdebbca159b8d75ea896326cadac7';
export default node;
