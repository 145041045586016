import { createContext, useContext } from 'react'
import type { ReactNode } from 'react'

/**
 * There is a tricky issue where if <a> tags render inside other <a> tags
 * React crashes with a hydration error. It is also invalid HTML.
 *
 * To get round this, we set some context when rendering a link so that
 * child components can behave differently if they are inside a link.
 *
 */

const LinkContext = createContext(false)

export const LinkProvider = ({ children }: { children: ReactNode }) => {
    return <LinkContext.Provider value={true}>{children}</LinkContext.Provider>
}

export const useIsInsideLink = () => {
    return useContext(LinkContext)
}
