/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AdvertWrapper_activeProductAdvert = {
    readonly id: string;
    readonly advertHandle: string;
    readonly destinationUrl: string;
    readonly sponsoringCompany: {
        readonly name: string;
    };
    readonly " $refType": "AdvertWrapper_activeProductAdvert";
};
export type AdvertWrapper_activeProductAdvert$data = AdvertWrapper_activeProductAdvert;
export type AdvertWrapper_activeProductAdvert$key = {
    readonly " $data"?: AdvertWrapper_activeProductAdvert$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AdvertWrapper_activeProductAdvert">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdvertWrapper_activeProductAdvert",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "advertHandle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "destinationUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SponsoringCompany",
      "kind": "LinkedField",
      "name": "sponsoringCompany",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductAdvert",
  "abstractKey": null
};
(node as any).hash = '4d347f709dc44e028aee9dfd91b63a1b';
export default node;
