import type { RefObject } from 'react'
import { useEffect, useRef } from 'react'

/**
 * For the purposes of tracking adverts, an impression is when the advert is scrolled into view.
 * This function will track the impression of an advert when it is scrolled into view, and trigger the event function.
 *
 * Note: this is different to being rendered into the dom, that doesn't necessarily mean it's been displayed to the user.
 *
 * This uses IntersectionObserver to detect when the an element intersects with the viewport, ie when it is scrolled into view.
 * If you're confused about how this works, check out the MDN docs below.
 *
 * Reference: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 *
 * @param ref - The ref of the element to track
 * @param eventFn - The function to call when the element is scrolled into view
 */
export default function useTrackImpression(ref: RefObject<HTMLElement>, eventFn: () => void) {
    const hasBeenIntersected = useRef(false) // If ref is set to true, the element has been intersected

    useEffect(() => {
        if (!ref?.current || hasBeenIntersected.current) return // Check the ref here

        const handleIntersection: IntersectionObserverCallback = ([entry], observer) => {
            // If the element is intersecting and hasn't been intersected before, call the event function
            if (entry.isIntersecting && !hasBeenIntersected.current) {
                hasBeenIntersected.current = true // set the ref to prevent the event firing again
                eventFn()

                if (ref.current) {
                    observer.unobserve(ref.current)
                }
            }
        }

        const observer = new IntersectionObserver(handleIntersection)

        observer.observe(ref.current)

        return () => observer.disconnect()
    }, [ref])

    return hasBeenIntersected.current
}
