import type { ReactElement } from 'react'
import React, { useRef } from 'react'

import { graphql, useFragment } from 'react-relay'

import { useAdvertisingTracking } from './Hooks/useAdvertisingTracking'

import type { AdvertWrapper_activeProductAdvert$key } from '__generated__/AdvertWrapper_activeProductAdvert.graphql'
import Link from 'components/Link'

type Props = {
    children: ReactElement
    advert: AdvertWrapper_activeProductAdvert$key
}

/**
 * Contains the logic for handling Linking to destination url and tracking. Should be used to wrap any advert CTA we want to display.
 *
 * This could be wrapped around and individual button or an entire advert depending on what is clickable.
 *
 * It is advised that the whole advert is wrapped in this component as it will allow us to track the whole advert being clicked.
 */
const AdvertWrapper = ({ children, advert: advertKey }: Props) => {
    const advert = useFragment(AdvertWrapperFragment, advertKey)
    const ref = useRef<HTMLDivElement>(null)

    const trackingParams = {
        advertHandle: advert.advertHandle,
        advertId: advert.id,
        companyName: advert.sponsoringCompany?.name || '#unknown',
    }

    const { onClick } = useAdvertisingTracking(ref, trackingParams)

    return (
        <div ref={ref}>
            <Link url={advert.destinationUrl} onClick={onClick}>
                {children}
            </Link>
        </div>
    )
}

const AdvertWrapperFragment = graphql`
    fragment AdvertWrapper_activeProductAdvert on ProductAdvert {
        id
        advertHandle
        destinationUrl
        sponsoringCompany {
            name
        }
    }
`

export default AdvertWrapper
