import React from 'react'

import styled from 'styled-components'

import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import { fonts } from 'theme'

type LocalListProps = {
    children: React.ReactNode
}

export type ListProps = LocalListProps & BoxProps

/*

    Creates a styled List for our content

    If we want to use this more widely, might want to 
    move to Components and add to component system more
    formally (docs etc)

*/

export const List = ({ children, as, className, color, ...props }: ListProps) => (
    <_List as={as} {...{ className, color }} {...props}>
        {children}
    </_List>
)

export default List

export const ListDefaultProps: Partial<ListProps> = {
    color: 'text',
    as: 'ul',
}
List.defaultProps = ListDefaultProps

const _List = styled(Box)<ListProps>`
    font-weight: ${fonts.weight.normal};
    line-height: 1.4;
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
    list-style-position: outside;
    padding-left: 1.5rem;
`
