import type { ElementType } from 'react'
import React from 'react'

import type { NodeType } from 'react-markdown'
import _Markdown from 'react-markdown'

import Heading from 'components/Heading'
import { LegacyLink as Link } from 'components/Link'
import { LinkProvider } from 'components/Markdown/LinkRendering/LinkProvider'
import { List } from 'components/Markdown/List'
import { ListItem } from 'components/Markdown/ListItem'
import Paragraph from 'components/Paragraph'

type renderersType = {
    [nodeType: string]: ElementType
}

export interface MarkdownParserProps {
    children: string
    overridingRenderers?: renderersType
}

// TODO: think of a better solution to this for later:
// currently only h1 + h2 in blocks in wagtail, adjust this as we add more (will default to normal sizing as before)
// this maps from _actual_ heading sizes to the sizes we want to display on mobile and desktop
// as we want to keep the heading semantics, but render them differently
// this is the rendered tag, not the wagtail rich text editor one
const markdownHeadingMapping: Record<string, string[] | undefined> = {
    h2: ['21px', '26px'], // seems like a huge difference...
    h3: ['15px', '22px'],
    default: undefined,
}

// whitelist what we support here TODO: Formalise this across web and
// app (and future-proof for corporate API)
const allowedTypes: NodeType[] = [
    'text',
    'paragraph',
    'strong',
    'emphasis',
    'link',
    'heading',
    'list',
    'listItem',
    'inlineCode',
]

const renderers = {
    paragraph: ({ children }: { children: React.ReactNode }) => {
        return (
            <Paragraph fontFamily="paragraph" lineHeight="1.5" fontSize={['1rem', null, '18px']}>
                {children}
            </Paragraph>
        )
    },
    link: ({ children, href }: { children: React.ReactNode; href: string }) => {
        // assume default open new tab for now
        return (
            <Link url={href} target="_blank">
                <LinkProvider>{children}</LinkProvider>
            </Link>
        )
    },
    // TODO: figure out how we're going to fix markdown headings
    heading: ({ children, level }: { children: React.ReactNode; level: number }) => {
        const headingSize = `h${level + 1}` // i.e. in our page heading hierarchy, the headings go from h1 -> h2, h2 -> h3 (useful for SEO)

        const fontSize =
            headingSize in markdownHeadingMapping
                ? markdownHeadingMapping[headingSize]
                : markdownHeadingMapping.default

        return (
            <Heading
                as={headingSize as ElementType}
                pt={[1, 2]}
                pb={[0, 1]}
                mb="8px !important"
                fontSize={fontSize}
                mt={0}
            >
                {children}
            </Heading>
        )
    },
    list: ({ children }: { children: React.ReactNode }) => {
        return (
            <List fontFamily="paragraph" lineHeight="1.5" fontSize={['1rem', null, '18px']}>
                {children}
            </List>
        )
    },
    listItem: ({ children }: { children: React.ReactNode }) => {
        return <ListItem>{children}</ListItem>
    },
}

const MarkdownParser = ({ children, overridingRenderers }: MarkdownParserProps) => {
    return (
        <_Markdown
            {...{
                allowedTypes,
                renderers: {
                    ...renderers,
                    ...overridingRenderers,
                },
            }}
        >
            {children}
        </_Markdown>
    )
}

export default MarkdownParser
