import { useCallback, useState } from 'react'

/**
 * Creates a "on first click" function to be added to an element.
 *
 * This function will only call the event function on the first click of the element.
 *
 * @param onClick - The function to call on the first click
 */
export default function useTrackClick(onClick: () => void) {
    const [hasBeenClicked, setHasBeenClicked] = useState(false)

    const trackClick = useCallback(() => {
        if (!hasBeenClicked) {
            onClick()
            setHasBeenClicked(true)
        }
    }, [hasBeenClicked, onClick])

    return trackClick
}
